import { useEffect } from 'react'

function getTrackingAttrs(elem) {
  if (!elem.dataset) {
    return null
  }

  const trackerKeys = Object.keys(elem.dataset).filter(k => /^tracker[A-Z]/.test(k))

  if (!trackerKeys.length) {
    return null
  }

  return trackerKeys.reduce((acc, key) => {
    acc[key.replace(/^tracker/, '').toLowerCase()] = elem.dataset[key]
    return acc
  }, {})
}

function findTrackableElement(elem) {
  if (shouldTrack(elem)) {
    return elem
  }

  return elem.parentNode ? findTrackableElement(elem.parentNode) : null
}

function shouldTrack(elem) {
  return elem.tagName === 'A' || elem.tagName === 'BUTTON' || !!getTrackingAttrs(elem)
}

function onClick(e) {
  const target = findTrackableElement(e.target)

  if (window.analytics && target) {

    const trackingAttrs = getTrackingAttrs(target) || {}
    const { name, ...data } = {
      ...{ name: 'click', text: target.getAttribute('data-tracker') ? target.getAttribute('data-tracker') : target.innerText },
      ...trackingAttrs,
    }

    window.analytics.track(name, data)
  }
}

function registerClicksHandler() {
  document.body.addEventListener('click', onClick)

  return () => document.removeEventListener('click', onClick)
}

function Tracker() {
  useEffect(registerClicksHandler, [])
  return null
}

export default Tracker
