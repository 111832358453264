import { useState, useEffect } from 'react'

export function useWindowSize() {
  function getSize() {
    return {
      width: window.innerWidth || undefined,
      height: window.innerHeight || undefined
    }
  }

  if (typeof window !== 'undefined') {
    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
      function handleResize() {
        setWindowSize(getSize())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [])
    return windowSize
  }
}

export function useIsMobile() {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search.substring(1)
    return queryString.includes('isMobile=true')
  }

  return false
}
