import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

// Utils
import {useWindowSize} from '../utils/hooks'

// Images
import logo from '../../static/logo.svg'

// Config
import {config} from '../config'

function Nav({ showNavOnMobile }) {
  const [isMobile, setIsMobile] = useState(false)
  const windowWidth = typeof window !== 'undefined' && useWindowSize().width

  useEffect(() => {
    setIsMobile(windowWidth < 562)
  }, [windowWidth])

  return (
    <nav className="navigation-container">
      <ul className="navigation">
        <div className="logo-container">
          <li className="logo">
            <Link data-tracker={"Logo - Top Nav Bar"} to="/">
              <img src={logo} alt="logo"/>
            </Link>
          </li>
        </div>
        {(!isMobile || showNavOnMobile) && (
          <>
            <div className="nav left">
              <li>
                <Link data-tracker={"How It Works - Top Nav Bar"} to="/#how-it-works">How it works</Link>
              </li>
              <li>
                <a data-tracker={"Templates - Top Nav Bar"} href={`${config.webAppUrl}/templates`}>Templates</a>
              </li>
            </div>
            <div className="nav right">
              <li>
                <a data-tracker={"Sign In - Top Nav Bar"} href={`${config.webAppUrl}/login`}>Sign in</a>
              </li>
              <li>
                <a data-tracker={"Create Account - Top Nav Bar"} href={`${config.webAppUrl}/login`}>Create account</a>
              </li>
            </div>
          </>
        )}
      </ul>
    </nav>
  )
}

Nav.propTypes = {
  showNavOnMobile: PropTypes.bool
}

Nav.defaultProps = {
  showNavOnMobile: true,
}

export default Nav
